body{
    font-weight: 600;
    font-family: $ff_jp;
    color: $c_base;
    line-height: 1.8;
    @if $type == sp{
        font-size: 14px;
    }
    @if $type == pc{
        font-size: 15px;
    }
}
ul li{
    list-style: none;
}
.common_inner{
    @if $type == sp{
        padding: 0 40px;
        &.sp_wide{
            padding: 0 20px;
        }
        &.sp_none{
            padding: 0;
        }
    }
    @if $type == pc{
        width: 96%;
        max-width: 1200px;
        margin: 0 auto;
        &.narrow{
            max-width: 860px;
        }
    }
}
.slick-dots{
    text-align: center;
    margin-top: 20px;
    li{
        display: inline-block;
        margin: 0 10px;
        padding-right: 10px;
        button{
            position: relative;
            text-indent: -9999px;
            &:before{
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid $c_base;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        &.slick-active{
            button:before{
                background-color: $c_base;
            }
        }
    }
    button{
        margin: 0;
        padding: 0;
        background: none;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
.jp{font-family: $ff_jp!important;}
.en{font-family: $ff_en!important;letter-spacing: 0.05em;}
#header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    &.active,&.second{
        @if $type == sp{
        }
        @if $type == pc{
            .gnav{
                padding-bottom: 0;
            }
        }
        .header_inner{
            background-color: #fff;
            @if $type == sp{
            }
            @if $type == pc{
                height: 70px;
                .logo img{
                    max-width: 144px;
                }
            }
        }
    }
    .header_inner{
        transition: .3s;
        @if $type == sp{
            width: 100%;
            height: 55px;
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
        }
        @if $type == pc{
            display: flex;
            align-items: center;
            width: 100%;
            height: 150px;
            padding: 0 45px;
            overflow: hidden;
        }
    }
    .logo{
        @if $type == sp{
            background: url(../images/common/logo.svg) no-repeat center;
            background-size: 107px 30px;
            width: 107px;
            height: 100%;
            position: relative;
            z-index: 1000;
            transition: .5s;
            &.active{
                background: url(../images/common/logo_w.svg) no-repeat center;
                background-size: 107px 30px;
            }
        }
        @if $type == pc{
            width: calc(100% / 3);
            display: flex;
            align-items: center;
            img{
                max-width: 184px;
                transition: .3s;
            }
        }
    }
    #menuBtn{
        @if $type == sp{
            width: 55px;
            height: 100%;
            background-color: $c_base;
            position: relative;
            z-index: 1000;
            span{
                display: inline-block;
                transition: all .4s;
                box-sizing: border-box;
                position: absolute;
                left: calc(50% - 12px);
                width: 24px;
                height: 1px;
                background-color: #fff;
                &:nth-of-type(1){
                    top: 19px;
                }
                &:nth-of-type(2){
                    top: 50%;
                }
                &:nth-of-type(3){
                    bottom: 17px;
                }
            }
            &.active{
                span{
                    &:nth-of-type(1){
                        transform: translateY(8px) rotate(-45deg);
                    }
                    &:nth-of-type(2){
                        opacity: 0;
                    }
                    &:nth-of-type(3){
                        transform: translateY(-10px) rotate(45deg);
                    }
                }
            }
        }
        @if $type == pc{
            display: none;
        }
    }
    .gnav{
        @if $type == sp{
            display: none;
            width: 100%;
            height: 100%;
            background-color: $c_base;
            position: fixed;
            top: 0;
            left: 0;
            padding-top: 90px;
        }
        @if $type == pc{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            width: calc(100% / 3 * 2);
        }
        .gnav_list{
            @if $type == sp{
            }
            @if $type == pc{
                display: flex;
                padding-left: 1.5vw;
            }
            li{
                @if $type == sp{
                    text-align: center;
                    &:not(:first-of-type){
                        margin-top: 50px;
                    }
                }
                @if $type == pc{
                    margin: 0px 20px;
                    &.current-menu-item,&.current-post-ancestor,&.current-page-ancestor,&.current-category-ancestor{
                        a:after{
                            transform: scale(1, 1);
                        }
                    }
                }
            }
            a{
                text-decoration: none;
                color: $c_text;
                font-family: $ff_en;
                letter-spacing: 0.05em;
                @if $type == sp{
                    font-size: 20px;
                    color: #fff;
                }
                @if $type == pc{
                    font-size: 16px;
                    position: relative;
                    display: inline-block;
                    &:after{
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        content: '';
                        width: 100%;
                        height: 1px;
                        background: $c_base;
                        transform: scale(0, 1);
                        transform-origin: left top;
                        transition: transform .3s;
                    }
                    &:hover:after{
                        transform: scale(1, 1);
                    }
                }
            }
        }
        .sns_list{
            @if $type == sp{
                margin-top: 50px;
                display: flex;
                justify-content: center;
            }
            @if $type == pc{
                display: flex;
                margin: 0 0 0 auto;
            }
            li{
                @if $type == sp{
                    &:not(:first-of-type){
                        margin-left: 35px;
                    }
                }
                @if $type == pc{
                    margin: 0 10px;
                }
            }
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                @if $type == sp{
                    background-color: #fff;
                    width: 45px;
                    height: 45px;
                    img{
                        width: 50%;
                    }
                }
                @if $type == pc{
                    background-color: $c_base;
                    width: 36px;
                    height: 36px;
                    transition: .3s;
                    img{
                        width: 50%;
                    }
                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
    }
}//header

#footer{
    background-color: $c_base;
    color: #fff;
    position: relative;
    @if $type == sp{
        padding: 110px 0 50px;
        text-align: center;
    }
    @if $type == pc{
        padding: 80px 0;
    }
    .title{
        letter-spacing: 0.05em;
        @if $type == sp{
            font-size: 23px;
        }
        @if $type == pc{
            font-size: 28px;
        }
    }
    .aux_logo{
        @if $type == sp{
            margin-top: 10px;
            img{
                height: 50px;
            }
        }
        @if $type == pc{
            margin-top: 10px;
            img{
                height: 50px;
            }
        }
    }
    .footer_content{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }
        > div{
            line-height: 2;
            @if $type == sp{
                font-size: 12px;
                margin-top: 30px;
            }
            @if $type == pc{
                font-size: 14px;
            }
            &.sns{
                p{
                    margin: 0;
                    @if $type == sp{
                    }
                    @if $type == pc{
                        font-size: 16px;
                        text-align: right;
                    }
                }
                .sns_list{
                    @if $type == sp{
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                    }
                    @if $type == pc{
                        display: flex;
                        margin: 10px 0 0 auto;
                    }
                    li{
                        @if $type == sp{
                            &:not(:first-of-type){
                                margin-left: 33px;
                            }
                        }
                        @if $type == pc{
                            margin: 0 7px;
                        }
                    }
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        @if $type == sp{
                            background-color: #fff;
                            width: 30px;
                            height: 30px;
                            img{
                                width: 50%;
                            }
                        }
                        @if $type == pc{
                            background-color: #fff;
                            width: 36px;
                            height: 36px;
                            transition: .3s;
                            img{
                                width: 50%;
                            }
                            &:hover{
                                opacity: .7;
                            }
                        }
                    }
                }
            }
        }
    }//footer_content
    .footer_bottom{
        @if $type == sp{
            margin-top: 40px;
        }
        @if $type == pc{
            margin-top: 30px;
        }
        p{
            margin: 0;
            &.company{
                @if $type == sp{
                    font-size: 18px;
                }
                @if $type == pc{
                    font-size: 25px;
                }
                a{
                    color: #fff;
                    text-decoration: none;
                }
            }
            &.copyright{
                @if $type == sp{
                    font-size: 12px;
                }
                @if $type == pc{
                    font-size: 16px;
                }
            }
        }
    }
}

#pageTop{
    @if $type == sp{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: #3c2c28;
    }
    @if $type == pc{
        border-radius: 50%;
        border: 1px solid $c_base;
        position: absolute;
        width: 53px;
        height: 53px;
        top: -70px;
        right: 20px;
        transition: .3s;
        &:hover{
            background-color: $c_base;
            &:after{
                border-top: solid 1px #fff;
                border-left: solid 1px #fff;
            }
        }
    }
    &:after{
        content: '';
        border: 0px;
        transform: rotate(45deg);
        position: absolute;
        @if $type == sp{
            border-top: solid 1px #fff;
            border-left: solid 1px #fff;
            width: 20px;
            height: 20px;
            top: calc(50% - 4px);
            right: calc(50% - 10px);
        }
        @if $type == pc{
            border-top: solid 1px #000;
            border-left: solid 1px #000;
            width: 12px;
            height: 12px;
            top: calc(50% - 3px);
            right: calc(50% - 6px);
        }
    }
}

.fade{
    opacity : 0;
    transition: opacity 3s;
}
.fade.view{
    opacity: 1;
}

.product_list{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }
    li{
        @if $type == sp{
            + li{
                margin-top: 65px;
            }
        }
        @if $type == pc{
            width: calc(50% - 40px);
            &:nth-of-type(odd){
                margin-right: 80px;
            }
            &:nth-of-type(n + 3){
                margin-top: 100px;
            }
        }
        .img{
            text-align: center;
            display: block;
            @if $type == sp{
            }
            @if $type == pc{
            }
            img{
                max-width: 100%;
            }
        }
        .text{
            border-top: 1px solid #c5c5c5;
            display: block;
            position: relative;
            @if $type == sp{
                padding-top: 20px;
                margin-top: 20px;
            }
            @if $type == pc{
                padding-top: 25px;
                margin-top: 40px;
                display: flex;
            }
            &:before{
                content: "";
                display: inline-block;
                height: 1px;
                width: 0px;
                background-color: #3e3634;
                position: absolute;
                top: -1px;
                left: 0;
                transition: .3s;
                @if $type == sp{
                    width: 100%;
                }
            }
            .title_wrap{
                @if $type == sp{
                    text-align: center;
                }
                @if $type == pc{
                    width: calc(100% - 120px);
                    padding-right: 20px;
                }
                .title{
                    line-height: 1.2;
                    font-weight: 600;
                    @if $type == sp{
                        font-size: 20px;
                    }
                    @if $type == pc{
                        font-size: 26px;
                    }
                }
                .sub_title{
                    font-family: $ff_jp;
                    @if $type == sp{
                        font-size: 12px;
                    }
                    @if $type == pc{
                        font-size: 13px;
                    }
                }
            }
            .more_btn{
                font-size: 16px;
                border-radius: 3px;
                line-height: 1;
                display: inline-block;
                text-decoration: none;
                border: 1px solid $c_text;
                transition: .3s;
                text-align: center;
                color: $c_text;
                @if $type == sp{
                    padding: 16px 40px 16px;
                    width: 90%;
                    display: block;
                    margin: 15px auto 0;
                }
                @if $type == pc{
                    padding: 16px 40px 13px;
                    width: 120px;
                    align-self: flex-start;
                }
            }
        }
    }
    a{
        text-decoration: none;
        color: $c_text;
        display: block;
        @if $type == sp{
        }
        @if $type == pc{
            &:hover{
                .text:before{
                    width: 100%;
                }
                .more_btn{
                    background-color: $c_base;
                    color: #fff;
                }
            }
        }
    }
}

.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}



/* ココから下には記入禁止 */

.forPC{    @if $type == sp{display: none!important;}}
.forSP{    @if $type == pc{display: none!important;}}

@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=Shippori+Mincho+B1:wght@400;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none;
  vertical-align: top; }

table {
  border-collapse: collapse; }

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0; }

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

@media screen and (max-width: 1000px) {
  body {
    font-weight: 600;
    font-family: "Shippori Mincho B1", serif;
    color: #231815;
    line-height: 1.8;
    font-size: 14px; }
  ul li {
    list-style: none; }
  .common_inner {
    padding: 0 40px; }
    .common_inner.sp_wide {
      padding: 0 20px; }
    .common_inner.sp_none {
      padding: 0; }
  .slick-dots {
    text-align: center;
    margin-top: 20px; }
    .slick-dots li {
      display: inline-block;
      margin: 0 10px;
      padding-right: 10px; }
      .slick-dots li button {
        position: relative;
        text-indent: -9999px; }
        .slick-dots li button:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid #231815;
          position: absolute;
          top: 0;
          left: 0; }
      .slick-dots li.slick-active button:before {
        background-color: #231815; }
    .slick-dots button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .jp {
    font-family: "Shippori Mincho B1", serif !important; }
  .en {
    font-family: "Crimson Text", serif !important;
    letter-spacing: 0.05em; }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; }
    #header.active .header_inner, #header.second .header_inner {
      background-color: #fff; }
    #header .header_inner {
      transition: .3s;
      width: 100%;
      height: 55px;
      padding-left: 20px;
      display: flex;
      justify-content: space-between; }
    #header .logo {
      background: url(../images/common/logo.svg) no-repeat center;
      background-size: 107px 30px;
      width: 107px;
      height: 100%;
      position: relative;
      z-index: 1000;
      transition: .5s; }
      #header .logo.active {
        background: url(../images/common/logo_w.svg) no-repeat center;
        background-size: 107px 30px; }
    #header #menuBtn {
      width: 55px;
      height: 100%;
      background-color: #231815;
      position: relative;
      z-index: 1000; }
      #header #menuBtn span {
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        position: absolute;
        left: calc(50% - 12px);
        width: 24px;
        height: 1px;
        background-color: #fff; }
        #header #menuBtn span:nth-of-type(1) {
          top: 19px; }
        #header #menuBtn span:nth-of-type(2) {
          top: 50%; }
        #header #menuBtn span:nth-of-type(3) {
          bottom: 17px; }
      #header #menuBtn.active span:nth-of-type(1) {
        transform: translateY(8px) rotate(-45deg); }
      #header #menuBtn.active span:nth-of-type(2) {
        opacity: 0; }
      #header #menuBtn.active span:nth-of-type(3) {
        transform: translateY(-10px) rotate(45deg); }
    #header .gnav {
      display: none;
      width: 100%;
      height: 100%;
      background-color: #231815;
      position: fixed;
      top: 0;
      left: 0;
      padding-top: 90px; }
      #header .gnav .gnav_list li {
        text-align: center; }
        #header .gnav .gnav_list li:not(:first-of-type) {
          margin-top: 50px; }
      #header .gnav .gnav_list a {
        text-decoration: none;
        color: #231815;
        font-family: "Crimson Text", serif;
        letter-spacing: 0.05em;
        font-size: 20px;
        color: #fff; }
      #header .gnav .sns_list {
        margin-top: 50px;
        display: flex;
        justify-content: center; }
        #header .gnav .sns_list li:not(:first-of-type) {
          margin-left: 35px; }
        #header .gnav .sns_list a {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #fff;
          width: 45px;
          height: 45px; }
          #header .gnav .sns_list a img {
            width: 50%; }
  #footer {
    background-color: #231815;
    color: #fff;
    position: relative;
    padding: 110px 0 50px;
    text-align: center; }
    #footer .title {
      letter-spacing: 0.05em;
      font-size: 23px; }
    #footer .aux_logo {
      margin-top: 10px; }
      #footer .aux_logo img {
        height: 50px; }
    #footer .footer_content > div {
      line-height: 2;
      font-size: 12px;
      margin-top: 30px; }
      #footer .footer_content > div.sns p {
        margin: 0; }
      #footer .footer_content > div.sns .sns_list {
        margin-top: 10px;
        display: flex;
        justify-content: center; }
        #footer .footer_content > div.sns .sns_list li:not(:first-of-type) {
          margin-left: 33px; }
        #footer .footer_content > div.sns .sns_list a {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #fff;
          width: 30px;
          height: 30px; }
          #footer .footer_content > div.sns .sns_list a img {
            width: 50%; }
    #footer .footer_bottom {
      margin-top: 40px; }
      #footer .footer_bottom p {
        margin: 0; }
        #footer .footer_bottom p.company {
          font-size: 18px; }
          #footer .footer_bottom p.company a {
            color: #fff;
            text-decoration: none; }
        #footer .footer_bottom p.copyright {
          font-size: 12px; }
  #pageTop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #3c2c28; }
    #pageTop:after {
      content: '';
      border: 0px;
      transform: rotate(45deg);
      position: absolute;
      border-top: solid 1px #fff;
      border-left: solid 1px #fff;
      width: 20px;
      height: 20px;
      top: calc(50% - 4px);
      right: calc(50% - 10px); }
  .fade {
    opacity: 0;
    transition: opacity 3s; }
  .fade.view {
    opacity: 1; }
  .product_list li + li {
    margin-top: 65px; }
  .product_list li .img {
    text-align: center;
    display: block; }
    .product_list li .img img {
      max-width: 100%; }
  .product_list li .text {
    border-top: 1px solid #c5c5c5;
    display: block;
    position: relative;
    padding-top: 20px;
    margin-top: 20px; }
    .product_list li .text:before {
      content: "";
      display: inline-block;
      height: 1px;
      width: 0px;
      background-color: #3e3634;
      position: absolute;
      top: -1px;
      left: 0;
      transition: .3s;
      width: 100%; }
    .product_list li .text .title_wrap {
      text-align: center; }
      .product_list li .text .title_wrap .title {
        line-height: 1.2;
        font-weight: 600;
        font-size: 20px; }
      .product_list li .text .title_wrap .sub_title {
        font-family: "Shippori Mincho B1", serif;
        font-size: 12px; }
    .product_list li .text .more_btn {
      font-size: 16px;
      border-radius: 3px;
      line-height: 1;
      display: inline-block;
      text-decoration: none;
      border: 1px solid #231815;
      transition: .3s;
      text-align: center;
      color: #231815;
      padding: 16px 40px 16px;
      width: 90%;
      display: block;
      margin: 15px auto 0; }
  .product_list a {
    text-decoration: none;
    color: #231815;
    display: block; }
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important; } }

@media screen and (min-width: 1000px) {
  body {
    font-weight: 600;
    font-family: "Shippori Mincho B1", serif;
    color: #231815;
    line-height: 1.8; }
  ul li {
    list-style: none; }
  .slick-dots {
    text-align: center;
    margin-top: 20px; }
    .slick-dots li {
      display: inline-block;
      margin: 0 10px;
      padding-right: 10px; }
      .slick-dots li button {
        position: relative;
        text-indent: -9999px; }
        .slick-dots li button:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid #231815;
          position: absolute;
          top: 0;
          left: 0; }
      .slick-dots li.slick-active button:before {
        background-color: #231815; }
    .slick-dots button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .jp {
    font-family: "Shippori Mincho B1", serif !important; }
  .en {
    font-family: "Crimson Text", serif !important;
    letter-spacing: 0.05em; }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; }
    #header.active .header_inner, #header.second .header_inner {
      background-color: #fff; }
    #header .header_inner {
      transition: .3s; }
    #header .gnav .gnav_list a {
      text-decoration: none;
      color: #231815;
      font-family: "Crimson Text", serif;
      letter-spacing: 0.05em; }
    #header .gnav .sns_list a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%; }
  #footer {
    background-color: #231815;
    color: #fff;
    position: relative; }
    #footer .title {
      letter-spacing: 0.05em; }
    #footer .footer_content > div {
      line-height: 2; }
      #footer .footer_content > div.sns p {
        margin: 0; }
      #footer .footer_content > div.sns .sns_list a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%; }
    #footer .footer_bottom p {
      margin: 0; }
      #footer .footer_bottom p.company a {
        color: #fff;
        text-decoration: none; }
  #pageTop:after {
    content: '';
    border: 0px;
    transform: rotate(45deg);
    position: absolute; }
  .fade {
    opacity: 0;
    transition: opacity 3s; }
  .fade.view {
    opacity: 1; }
  .product_list li .img {
    text-align: center;
    display: block; }
    .product_list li .img img {
      max-width: 100%; }
  .product_list li .text {
    border-top: 1px solid #c5c5c5;
    display: block;
    position: relative; }
    .product_list li .text:before {
      content: "";
      display: inline-block;
      height: 1px;
      width: 0px;
      background-color: #3e3634;
      position: absolute;
      top: -1px;
      left: 0;
      transition: .3s; }
    .product_list li .text .title_wrap .title {
      line-height: 1.2;
      font-weight: 600; }
    .product_list li .text .title_wrap .sub_title {
      font-family: "Shippori Mincho B1", serif; }
    .product_list li .text .more_btn {
      font-size: 16px;
      border-radius: 3px;
      line-height: 1;
      display: inline-block;
      text-decoration: none;
      border: 1px solid #231815;
      transition: .3s;
      text-align: center;
      color: #231815; }
  .product_list a {
    text-decoration: none;
    color: #231815;
    display: block; }
  /* ココから下には記入禁止 */ }

@media print, screen and (min-width: 1001px) {
  body {
    font-weight: 600;
    font-family: "Shippori Mincho B1", serif;
    color: #231815;
    line-height: 1.8;
    font-size: 15px; }
  ul li {
    list-style: none; }
  .common_inner {
    width: 96%;
    max-width: 1200px;
    margin: 0 auto; }
    .common_inner.narrow {
      max-width: 860px; }
  .slick-dots {
    text-align: center;
    margin-top: 20px; }
    .slick-dots li {
      display: inline-block;
      margin: 0 10px;
      padding-right: 10px; }
      .slick-dots li button {
        position: relative;
        text-indent: -9999px; }
        .slick-dots li button:before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid #231815;
          position: absolute;
          top: 0;
          left: 0; }
      .slick-dots li.slick-active button:before {
        background-color: #231815; }
    .slick-dots button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .jp {
    font-family: "Shippori Mincho B1", serif !important; }
  .en {
    font-family: "Crimson Text", serif !important;
    letter-spacing: 0.05em; }
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; }
    #header.active .gnav, #header.second .gnav {
      padding-bottom: 0; }
    #header.active .header_inner, #header.second .header_inner {
      background-color: #fff;
      height: 70px; }
      #header.active .header_inner .logo img, #header.second .header_inner .logo img {
        max-width: 144px; }
    #header .header_inner {
      transition: .3s;
      display: flex;
      align-items: center;
      width: 100%;
      height: 150px;
      padding: 0 45px;
      overflow: hidden; }
    #header .logo {
      width: calc(100% / 3);
      display: flex;
      align-items: center; }
      #header .logo img {
        max-width: 184px;
        transition: .3s; }
    #header #menuBtn {
      display: none; }
    #header .gnav {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 5px;
      width: calc(100% / 3 * 2); }
      #header .gnav .gnav_list {
        display: flex;
        padding-left: 1.5vw; }
        #header .gnav .gnav_list li {
          margin: 0px 20px; }
          #header .gnav .gnav_list li.current-menu-item a:after, #header .gnav .gnav_list li.current-post-ancestor a:after, #header .gnav .gnav_list li.current-page-ancestor a:after, #header .gnav .gnav_list li.current-category-ancestor a:after {
            transform: scale(1, 1); }
        #header .gnav .gnav_list a {
          text-decoration: none;
          color: #231815;
          font-family: "Crimson Text", serif;
          letter-spacing: 0.05em;
          font-size: 16px;
          position: relative;
          display: inline-block; }
          #header .gnav .gnav_list a:after {
            position: absolute;
            bottom: -4px;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background: #231815;
            transform: scale(0, 1);
            transform-origin: left top;
            transition: transform .3s; }
          #header .gnav .gnav_list a:hover:after {
            transform: scale(1, 1); }
      #header .gnav .sns_list {
        display: flex;
        margin: 0 0 0 auto; }
        #header .gnav .sns_list li {
          margin: 0 10px; }
        #header .gnav .sns_list a {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #231815;
          width: 36px;
          height: 36px;
          transition: .3s; }
          #header .gnav .sns_list a img {
            width: 50%; }
          #header .gnav .sns_list a:hover {
            opacity: .7; }
  #footer {
    background-color: #231815;
    color: #fff;
    position: relative;
    padding: 80px 0; }
    #footer .title {
      letter-spacing: 0.05em;
      font-size: 28px; }
    #footer .aux_logo {
      margin-top: 10px; }
      #footer .aux_logo img {
        height: 50px; }
    #footer .footer_content {
      display: flex;
      justify-content: space-between;
      margin-top: 40px; }
      #footer .footer_content > div {
        line-height: 2;
        font-size: 14px; }
        #footer .footer_content > div.sns p {
          margin: 0;
          font-size: 16px;
          text-align: right; }
        #footer .footer_content > div.sns .sns_list {
          display: flex;
          margin: 10px 0 0 auto; }
          #footer .footer_content > div.sns .sns_list li {
            margin: 0 7px; }
          #footer .footer_content > div.sns .sns_list a {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #fff;
            width: 36px;
            height: 36px;
            transition: .3s; }
            #footer .footer_content > div.sns .sns_list a img {
              width: 50%; }
            #footer .footer_content > div.sns .sns_list a:hover {
              opacity: .7; }
    #footer .footer_bottom {
      margin-top: 30px; }
      #footer .footer_bottom p {
        margin: 0; }
        #footer .footer_bottom p.company {
          font-size: 25px; }
          #footer .footer_bottom p.company a {
            color: #fff;
            text-decoration: none; }
        #footer .footer_bottom p.copyright {
          font-size: 16px; }
  #pageTop {
    border-radius: 50%;
    border: 1px solid #231815;
    position: absolute;
    width: 53px;
    height: 53px;
    top: -70px;
    right: 20px;
    transition: .3s; }
    #pageTop:hover {
      background-color: #231815; }
      #pageTop:hover:after {
        border-top: solid 1px #fff;
        border-left: solid 1px #fff; }
    #pageTop:after {
      content: '';
      border: 0px;
      transform: rotate(45deg);
      position: absolute;
      border-top: solid 1px #000;
      border-left: solid 1px #000;
      width: 12px;
      height: 12px;
      top: calc(50% - 3px);
      right: calc(50% - 6px); }
  .fade {
    opacity: 0;
    transition: opacity 3s; }
  .fade.view {
    opacity: 1; }
  .product_list {
    display: flex;
    flex-wrap: wrap; }
    .product_list li {
      width: calc(50% - 40px); }
      .product_list li:nth-of-type(odd) {
        margin-right: 80px; }
      .product_list li:nth-of-type(n + 3) {
        margin-top: 100px; }
      .product_list li .img {
        text-align: center;
        display: block; }
        .product_list li .img img {
          max-width: 100%; }
      .product_list li .text {
        border-top: 1px solid #c5c5c5;
        display: block;
        position: relative;
        padding-top: 25px;
        margin-top: 40px;
        display: flex; }
        .product_list li .text:before {
          content: "";
          display: inline-block;
          height: 1px;
          width: 0px;
          background-color: #3e3634;
          position: absolute;
          top: -1px;
          left: 0;
          transition: .3s; }
        .product_list li .text .title_wrap {
          width: calc(100% - 120px);
          padding-right: 20px; }
          .product_list li .text .title_wrap .title {
            line-height: 1.2;
            font-weight: 600;
            font-size: 26px; }
          .product_list li .text .title_wrap .sub_title {
            font-family: "Shippori Mincho B1", serif;
            font-size: 13px; }
        .product_list li .text .more_btn {
          font-size: 16px;
          border-radius: 3px;
          line-height: 1;
          display: inline-block;
          text-decoration: none;
          border: 1px solid #231815;
          transition: .3s;
          text-align: center;
          color: #231815;
          padding: 16px 40px 13px;
          width: 120px;
          align-self: flex-start; }
    .product_list a {
      text-decoration: none;
      color: #231815;
      display: block; }
      .product_list a:hover .text:before {
        width: 100%; }
      .product_list a:hover .more_btn {
        background-color: #231815;
        color: #fff; }
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important; } }
